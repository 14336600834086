.campaign-metrics-name {
  font-size: 14px;
  color: #3F4254;
  font-weight: bold;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}
.background-color-1 {
  background: #4ec5f3 !important;
}
.background-color-2 {
  background: #eb13a6 !important;
}
.background-color-3 {
  background: #f39547 !important;
}
.campaign-icon {
  padding: 15px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
}
.campaign-metrics-value {
  font-size: 22px;
  font-weight: bolder;
  color: #000;
}

.metrics-container {
  padding: 10px 20px;
  width: 100%;
  overflow: hidden;
  height: 100px;
}
.metrics-value {
  width: 40%;
  /* height: 100%; */
  word-wrap: break-word;
  font-weight: bolder;
  text-align: right;
  float: right;
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  /* line-height: 60px; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.metrics-name {
  width: 60%;
  /* white-space: pre-line; */
  word-wrap: break-word;
  /* height: 100%; */
  font-weight: 700;
  text-align: left;
  float: left;
  display: table-cell;
  vertical-align: middle;
  /* line-height: 60px; */
  font-size: 14px;
  color: #666;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
}
