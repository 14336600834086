.editor {
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
  font-weight: 400;
  height: 340px;
  letter-spacing: normal;
  line-height: 20px;
  padding: 10px;
  tab-size: 4;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  /* background: #282a36; */
}

.editor.hljs {
  padding: 10px;
}

.editor > div[contenteditable~='plaintext-only'] {
  height: 200px;
  overflow-x: scroll;
}
