.customSubmenu {
  display: flex;
  align-items: center;
  line-height: 0;
  vertical-align: middle;
  text-align: left;
  flex: 0 0 15px;
}

.aside-minimize-hover .campaignSelectIcon, .aside-minimize-hoverable .campaignSelectIcon{
  visibility: collapse;
}

.reactSelectWidth {
  width: 100%;
}