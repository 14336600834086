.console {
  /* position: fixed; */
  font-family: monospace, monospace;
  color: #fff;
  width: 100%;
  /* width: calc(100% - 3
    em
    ); */
  max-width: 100%;
  /* max-height: calc(100% - 3
    em
    ); */
  overflow-y: auto;
  margin: 1em 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background: rgb(11 11 11 / 87%);
  border-radius: 0.5em;
  /* box-shadow: 0 0.75em 3em rgb(50 50 50 / 50%); */
  z-index: 100;
  line-height: 1.5;
}

.console-input {
  height: 200px;
  font-family: monospace, monospace;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: calc(100% - 2em);
  resize: none;
}

.console-input:before {
  content: '> ';
  color: #fc3565;
  vertical-align: top;
}

.output-cmd:before {
  content: '> ';
  color: #fc3565;
  vertical-align: top;
}
.output-text:before {
  content: '> ';
  color: #5f8787;
  font-weight: 600 !important;
  vertical-align: top;
}

.output-text,
.output-cmd {
  width: 100%;
  height: 60vh;
  display: block;
}

.console-inner {
  padding: 0.3em 1.1em;
}

.output-text,
.output-cmd {
  display: block;
  white-space: pre;
}

#outputs div {
  opacity: 0.85;
}

#outputs div div {
  color: #46f01d;
  opacity: 0.8;
  text-decoration: none;
}

#outputs a {
  color: #46f01d;
  opacity: 0.8;
  text-decoration: underline;
}

.console-inner .output-text#ready {
  color: #3df5b8;
  font-style: italic;
  opacity: 0.75;
}

.particles-js-canvas-el {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
